(function(){

	var alvo = $('[data-trocar-fa="true"]');

	var botaoCarregaFas = alvo.find('.btn');

	botaoCarregaFas.each(function(){

		$(this).click(function(){
			
			var fas = $(this).find('.fas');

			fas.toggleClass('fa-plus fa-minus');

		});

	});

})();